/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const DashboardContext = createContext<any>('');
export function DashboardContextProvider(props: any) {
    const [actionEvent, setActionEvent] = useState(null);
    const [webLeadRefundPopupFlag, setWebLeadRefundPopupFlag] = React.useState<boolean>(false)
    const [selectedLead, setSelectedLead] = React.useState(null);
    return (
        <div>
            <DashboardContext.Provider
                value={{
                  actionEvent,
                  setActionEvent, 
                  webLeadRefundPopupFlag, 
                  setWebLeadRefundPopupFlag, 
                  selectedLead, 
                  setSelectedLead
                }}
            >
                {props.children}
            </DashboardContext.Provider>
        </div>
    );
}
