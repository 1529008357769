/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const FeatureFlagContext = createContext<any>('');
export function FeatureFlagContextProvider(props: any) {
    const [fieldDeviceParamKey, setfieldDeviceParamKey] = useState('');
    const [pilotStoreParamKey, setpilotStoreParamKey] = useState('')
    const [upcomingDapParamKey, setUpcomingDapParamKey] = useState('');
    const [taskandGoals, setTaskandGoals] = useState('')
    const [customerSurveyParamKey, setCustomerSurveyParamKey] = useState('')
    const [updateRecieptKey, setUpdateRecieptKey] = useState(null)
    const [customerVerification, setCustomerVerification] = useState('');
    const [enableWebLeadDeposit, setEnableWebleadDeposit] = useState<boolean>(false);
    return (
        <div>
            <FeatureFlagContext.Provider
                value={{
                    fieldDeviceParamKey,
                    setfieldDeviceParamKey,
                    pilotStoreParamKey,
                    setpilotStoreParamKey,
                    updateRecieptKey,
                    setUpdateRecieptKey,
                    upcomingDapParamKey,
                    setUpcomingDapParamKey,
                    taskandGoals,
                    setTaskandGoals,
                    customerSurveyParamKey,
                    setCustomerSurveyParamKey,
                    customerVerification,
                    setCustomerVerification, 
                    enableWebLeadDeposit, 
                    setEnableWebleadDeposit
                }}
            >
                {props.children}
            </FeatureFlagContext.Provider>
        </div>
    );
}
